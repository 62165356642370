<template>
  <div class="container-fluid">
    <PageTitle noAdd />

    <b-overlay :show="loadingOverlayTb || loadingOverlay">
      <VTab>
        <template slot="link">
          <VTabLink :step.sync="tab" option="manage">Atur Periode</VTabLink>
          <VTabLink :step.sync="tab" option="logs">Periode Logs</VTabLink>
        </template>
        <template slot="content">
          <VTabContent :step.sync="tab" option="manage">
            <b-card>
              <b-card-title>
                <b-row>
                  <b-col>
                    Atur Periode
                  </b-col>
                  <b-col v-if="periode.status && noChange" align="right">
                    <span class="badge badge-info">{{ periode.status }}</span>
                  </b-col>
                </b-row>
              </b-card-title>

              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitConfirm)">
                  <b-row>
                    <b-col md="8">
                      <b-form-group label="Periode Untuk:">
                        <b-row>
                          <b-col>
                            <b-input
                              v-model="periode.tahun"
                              placeholder="e.g. 2021"
                              type="number"
                              step="1"
                              :disabled="!!periode.status"
                              @input="noChange=false"
                            />
                            <VValidate
                              name="Tahun"
                              v-model="periode.tahun"
                              :rules="{required:1, min_value:1990, max_value:$moment(datebypass).format('YYYY')}"
                            />
                          </b-col>
                          <b-col>
                            <VSelect
                              v-model="periode.bulan"
                              placeholder="-Pilih Bulan-"
                              :options="Config.master.OptionBulan"
                              :reduce="(v) => v.value"
                              :disabled="!!periode.status"
                              @input="noChange=false"
                            />
                            <VValidate
                              name="Bulan"
                              v-model="periode.bulan"
                              rules="required"
                            />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <b-col md=8>
                      <b-form-group label="Tanggal Awal Periode:" label-cols="4">
                        <b-form-datepicker
                          v-model="periode.start_date"
                          :date-format-options="date_format"
                          :min="start_date_min"
                          :max="start_date_max"
                          locale="id"
                          @input="noChange=false"
                        />
                        <VValidate
                          name="Tanggal Awal Periode"
                          v-model="periode.start_date"
                          :rules="{required:1}"
                        />
                      </b-form-group>
                      <b-form-group label="Tanggal Threshold:" label-cols="4">
                        <b-form-datepicker
                          v-model="periode.threshold_date"
                          :date-format-options="date_format"
                          :min="start_date_min"
                          :max="end_date_max"
                          locale="id"
                          @input="noChange=false"
                        />
                        <VValidate
                          name="Tanggal Threshold"
                          v-model="periode.threshold_date"
                          :rules="{required:1}"
                        />
                      </b-form-group>
                      <b-form-group label="Tanggal Akhir Periode:" label-cols="4">
                        <b-form-datepicker
                          v-model="periode.end_date"
                          :date-format-options="date_format"
                          :min="end_date_min"
                          :max="end_date_max"
                          locale="id"
                          @input="noChange=false"
                        />
                        <VValidate
                          name="Tanggal Akhir Periode"
                          v-model="periode.end_date"
                          :rules="{required:1}"
                        />
                      </b-form-group>
                      <!-- <b-form-group label="" label-cols="4">
                        <b-form-checkbox
                          v-model="periode.custom_next_periode"
                        >
                          Atur Manual Periode Selanjutnya
                        </b-form-checkbox>
                      </b-form-group> -->
                    </b-col>
                  </b-row>

                  <b-card style="background:#eee" title="Periode Selanjutnya">
                    <b-row>
                      <b-col md=8>
                        <b-form-group label="Tanggal Awal Periode:" label-cols="4">
                          <b-form-datepicker
                            v-model="periode.next_start_date"
                            :date-format-options="date_format"
                            :min="next_start_date_min"
                            :max="next_start_date_max"
                            :disabled="!periode.custom_next_periode"
                            locale="id"
                          />
                          <VValidate
                            name="Next Periode: Tanggal Awal Periode"
                            v-model="periode.next_start_date"
                            :rules="{required:1}"
                          />
                        </b-form-group>
                        <b-form-group label="Tanggal Threshold:" label-cols="4">
                          <b-form-datepicker
                            v-model="periode.next_threshold_date"
                            :date-format-options="date_format"
                            :min="next_start_date_min"
                            :max="next_end_date_max"
                            :disabled="!periode.custom_next_periode"
                            locale="id"
                          />
                          <VValidate
                            name="Next Periode: Tanggal Threshold"
                            v-model="periode.next_threshold_date"
                            :rules="{required:1}"
                          />
                        </b-form-group>
                        <b-form-group label="Tanggal Akhir Periode:" label-cols="4">
                          <b-form-datepicker
                            v-model="periode.next_end_date"
                            :date-format-options="date_format"
                            :min="next_end_date_min"
                            :max="next_end_date_max"
                            :disabled="!periode.custom_next_periode"
                            locale="id"
                          />
                          <VValidate
                            name="Next Periode: Tanggal Akhir Periode"
                            v-model="periode.next_end_date"
                            :rules="{required:1}"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <div v-if="!moduleRole('ViewOnly')" align="right">
                    <b-button type="submit" variant="info" size="lg" :disabled="noChange">
                      Simpan Perubahan
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </b-card>
          </VTabContent>
          <VTabContent :step.sync="tab" option="logs">
            <b-card no-body>
              <b-card-header class="bg-white pl-0 pr-0">
                <b-row>
                  <b-col md=8> </b-col>
                  <b-col md=4>
                    <b-input-group>
                      <b-input 
                        v-model="TSearch"
                        placeholder="Ketik untuk mencari ..."
                      />
                      <b-input-group-append>
                        <b-button
                          variant="info"
                          size="sm"
                          class="btn-icon"
                        >
                          <i class="icon-magnifier"></i>
                        </b-button>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          size="sm"
                          class="btn-icon"
                          @click="TSearch=''"
                        >
                          Reset
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-table
                id="my-table2"
                :fields="TField"
                :items="TLogsData"
                :per-page="TPerPage"
                :current-page="TCurrentPage"
                small
                show-empty
                thead-tr-class="full-color-table full-purple-table"
                bordered
                responsive
              >
                <template #empty>
                  <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                </template>
                <template #cell(start_date)="data">
                  {{ data.value | moment('DD MMM YYYY') }}
                </template>
                <template #cell(threshold_date)="data">
                  {{ data.value | moment('DD MMM YYYY') }}
                </template>
                <template #cell(end_date)="data">
                  {{ data.value | moment('DD MMM YYYY') }}
                </template>
              </b-table>
              <b-row>
                <b-col 
                  v-if="TLogsData.length > TPerPage"
                  cols="auto"
                >
                  <b-input-group >
                    <template #prepend>
                      <b-input-group-text>Show</b-input-group-text>
                    </template>
                    <b-form-select
                      class="w-auto mx-50"
                      v-model="TPerPage"
                      :options="Config.dataShownOptions"
                    />
                    <template #append>
                      <b-input-group-text>items per page</b-input-group-text>
                    </template>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-pagination
                    v-if="TLogsData.length >= TPerPage"
                    v-model="TCurrentPage"
                    :total-rows="TLogsData.length"
                    :per-page="TPerPage"
                    aria-controls="my-table2"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </VTabContent>
        </template>
      </VTab>
    </b-overlay>
      
  </div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let $ = global.jQuery
export default {
  extends:GlobalVue,
  components: { 
    PageTitle ,
    VTab, VTabLink, VTabContent
  },
  data(){
    return {
      tab:'manage',
      logs:[],
      periode:{},
      TPerPage:10,
      TSearch:"",
      TCurrentPage:1,
      TField:[
        { key:'tahun' },
        { key:'bulan' },
        { key:'start_date', label:'Tanggal Awal Periode' },
        { key:'threshold_date', label:'Tanggal Threshold' },
        { key:'end_date', label:'Tanggal Akhir Periode' },
      ],
      noChange:true
    }
  },
  computed:{
    datebypass(){
      return this.$route.query.datebypass||new Date()
    },
    start_date_min(){
      let date = this.$moment(this.datebypass).format('YYYY-MM-DD');

      if(this.periode.tahun && this.periode.bulan && !this.$route.query.datebypass){
        date = this.periode.tahun+'-'+this.periode.bulan+'-01'
      }

      return date
    },
    start_date_max(){
      let date = null

      if(this.periode.tahun && this.periode.bulan && !this.$route.query.datebypass){
        date = this.periode.tahun+'-'+this.periode.bulan+'-01'
      }

      return this.$moment(date).endOf('month').format('YYYY-MM-DD')
    },
    end_date_min(){
      return this.start_date_max
    },
    end_date_max(){
      let date = this.$moment(this.datebypass).add(1,'months').endOf('month').format('YYYY-MM-DD')
      
      if(this.periode.start_date){
        let start_date = this.$moment(this.periode.start_date)
        let next_date = start_date.add(30,'days')
        
        if((next_date.get("month")-start_date.get("month"))>=2){
          // lebih dari 1 bln
          date = this.$moment(this.periode.start_date).add(1,'months').format('YYYY-MM-DD')
        }else{
          date = next_date.format('YYYY-MM-DD')
        }
      }

      return date
    },
    next_start_date_min(){
      let date = this.next_end_date_max

      if(this.periode.end_date){
        date = this.$moment(this.periode.end_date).add(1,'days').format('YYYY-MM-DD')
      }

      return date
    },
    next_start_date_max(){
      return this.$moment(this.next_start_date_min).endOf('month').format('YYYY-MM-DD')
    },
    next_end_date_min(){
      return this.next_start_date_max
    },
    next_end_date_max(){
      let date = this.$moment(this.datebypass).add(1,'months').endOf('month').format('YYYY-MM-DD')
      
      if(this.periode.next_start_date){
        let start_date = this.$moment(this.periode.next_start_date)
        let next_date = start_date.add(30,'days')
        
        if((next_date.get("month")-start_date.get("month"))>=2){
          // lebih dari 1 bln
          date = this.$moment(this.periode.next_start_date).add(1,'months').format('YYYY-MM-DD')
        }else{
          date = next_date.format('YYYY-MM-DD')
        }
      }

      return date
    },
    date_format(){
      return { year: 'numeric', month: 'short', day: 'numeric' }
    }
  },
  methods:{
    doSubmitConfirm(){
      let now = this.$moment(this.datebypass)
      let start_date = this.$moment(this.periode.start_date)
      let end_date = this.$moment(this.periode.end_date)
      let status, title, text;

      if(
        now.diff(start_date, 'days') < 0
      ){
        // UPCOMING
        status = "UPCOMING"
        title = "Anda Yakin Ingin Mengubah Tanggal Periode?"
        text = 'Periode bulan selanjutnya akan ditandai sebagai UPCOMING. Aktifitas register risiko dapat dilakukan ketika tanggal awal periode telah dimulai."'
      }else if(
        now.diff(start_date, 'days') >= 0 && 
        now.diff(end_date, 'days') < 0
      ){
        // ONGOING
        status = "ONGOING"
        title = "Apa Anda Yakin Ingin Melanjutkan?"
        text = 'Periode ditandai sebagai "ONGOING", jika Anda klik Lanjutkan, periode sebelumnya ditandai sebagai ONGOING/SEDANG BERJALAN dan register periode-nya yang masih onprogress tidak bisa dilanjutkan lagi.'
      }else if(
        now.diff(end_date, 'days') > 0
      ){
        // PASSED
        status = "PASSED"
        title = "Apa Anda Yakin Ingin Melanjutkan?"
        text = 'Periode ditandai sebagai "PASSED", jika Anda klik Lanjutkan, periode ini ditandai sebagai PASSED/ SELESAI dan register periode ini yang masih onprogress tidak bisa dilanjutkan lagi.'
      }

      if(this.periode.status!=status){
        global.Swal.fire({
          icon:'warning',
          title:title,
          text:text,
          showCancelButton: true,
          confirmButtonColor: "#30d64c",
          cancelButtonColor: '#3085d6',
          confirmButtonText:  "Ya, Lanjutkan",
          cancelButtonText:  `Batal`,
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result=>{
          if(result.value){
            this.$set(this.periode, 'status', status)
            this.doSubmitForm()
          }
        })
      }else{
        this.$set(this.periode, 'status', status)
        this.doSubmitForm()
      }
    },
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'update',
          periode: this.periode,
          datebypass:this.datebypass
        }, 
        (type, resp)=>{
          if(type=='success'){
            this.noChange=true
            this.$router.push({name:this.$route.name})
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            }else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        }
      )
    },
    TLogsData(){
      if(!this.TSearch){
         return this.logs
      }else{
        let TSearch = (this.TSearch||"").toLowerCase()
        return _.filter(
          this.logs, 
          (v)=>{
            return ( 
              (v.tahun||"").toLowerCase().includes(TSearch)  ||
              (v.bulan||"").toLowerCase().includes(TSearch)
            )
          }
        )
      }
    },
  },
  mounted() {
    this.apiGet();
  },
  watch:{
    'periode.tahun'(){
      if(this.$route.query.datebypass){
        this.$set(this.periode,'tahun', this.$moment(this.$route.query.datebypass).format('YYYY'))
        this.$set(this.periode,'bulan', parseInt(this.$moment(this.$route.query.datebypass).format('MM')))
      }
    },
    'periode.start_date'(v,o){
      if(v && !o) return;
      this.$set(this.periode,'threshold_date',null)
      this.$set(this.periode,'end_date',null)
      this.$set(this.periode,'next_start_date',null)
      this.$set(this.periode,'next_threshold_date',null)
      this.$set(this.periode,'next_end_date',null)
    },
    'periode.end_date'(){
      this.$set(this.periode,'custom_next_periode',false)
      
      this.$set(this.periode,'next_start_date',this.next_start_date_min)

      let th_diff = this.$moment(this.periode.threshold_date).diff(this.$moment(this.periode.start_date), 'days')
      let th_date = this.$moment(this.periode.next_start_date).add(th_diff,'days').format('YYYY-MM-DD')
      this.$set(this.periode,'next_threshold_date',th_date)

      th_diff = this.$moment(this.periode.end_date).diff(this.$moment(this.periode.start_date), 'days')
      th_date = this.$moment(this.periode.next_start_date).add(th_diff,'days').format('YYYY-MM-DD')
      this.$set(this.periode,'next_end_date',th_date)
    },
    $route(){
      this.apiGet()
    }
  },
}
</script>